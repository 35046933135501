import React from 'react'
import { Link } from 'gatsby';
import { Layout } from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="section">
      <div className="container has-text-centered">
        <h1 className="title">Sorry, that page doesn’t exist!</h1>
        <p className="subtitle">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
    <section className="section top-paddingless">
      <div className="container has-text-centered">
        <Link className="button is-rounded is-outlined is-dark" to="/">Go to Main Page</Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage;
